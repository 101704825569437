* {
  -webkit-overflow-scrolling: touch;
}
*,*:before,*:after { box-sizing:inherit }

@keyframes fade {
  from { opacity: 1; }
  to { opacity: 0.25; }
}


html, body {
  background: transparent;
  font-size: 16px;
  box-sizing:border-box;
  color: #4D4D4D;
  height: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  position: relative;
  overflow: hidden;
}

@media screen and (max-width: 320px) {
  html, body {
    font-size: 14px;
  }
}

body {
  margin: 0;
  font-family: "Noto Sans JP",sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

strong {
  font-weight: 600;
}


button, input[type="submit"], input[type="reset"] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

textarea, input {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

a {
  font-size: .75rem;
  color: #00AFF0;
  text-decoration: underline;
  cursor: pointer;
}

#__next {
  text-align: center;
  margin: 0 auto;
  max-width: 100%;
}

.ReactModal__Overlay {
  background-color: rgba(98,98,98,.5) !important;
  opacity: 0;
  transition: opacity 150ms ease-in-out;
}

.ReactModal__Overlay .overlay-white {
  background-color: rgba(98,98,98,.2) !important;
}

.ReactModal__Overlay * {
  outline: none;
  max-width: 740px;
}
.ReactModal__Overlay *:focus {
  outline: none;
}

.ReactModal__Overlay .slide-modal--overlay {
  background-color: transparent !important;
  opacity: 1;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  transform: translate(0, 100%);
  transition: transform .5s ;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  z-index: 999;
}

.ReactModal__Overlay--after-open .slide-modal--overlay{
  opacity: 1;
  transform: translate(0, 0);
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Overlay--before-close .slide-modal--overlay {
  opacity: 1;
  transform: translate(0, 100%);
}
.ReactModal__Content {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ReactModal__Content .slide-modal {
  top: initial;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
}
text {
  text-anchor: inherit;
}
.build-id {
  right: 10px;
  bottom: 10px;
  position: fixed;
  font-weight: 400;
  font-size: 14px;
  font-family: HelveticaNeue, "Helvetica Neue";
}
#currency_chart::-webkit-scrollbar {
  display: none;
}
#currency_chart {
  padding-top: 13px;
  padding-bottom: 11px;
}
.loading-txt {
  height: calc(var(--vh, 1vh) * 100);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.help-popup-text div{
  font-weight: 500;
}

@media (max-height: 400px) {
  #currency_chart {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

#order-block, #profit-block {
  height: 207px;
}
@media (min-width: 768px) {
  #order-block, #profit-block {
    height: 228px;
  }
}

.echarts-for-react {
  font-family: 'Oxygen', sans-serif;
}

/* dev-v0.3.8 */
